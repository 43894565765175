import { mapState, mapGetters, mapActions } from 'vuex'

export const authComputed = {
  ...mapState('auth', {
    currentUser: (state) => state.currentUser,
  }),
  ...mapGetters('auth', ['loggedIn']),
}

export const layoutComputed = {
  ...mapState('layout', {
    layoutType: (state) => state.layoutType,
    leftSidebarType: (state) => state.leftSidebarType,
    layoutWidth: (state) => state.layoutWidth,
    topbar: (state) => state.topbar,
    loader: (state) => state.loader
  })
}

export const authUsuarioComputed = {
  ...mapState('auth', {
    user: (state) => state.user,

  })
}
export const authMethods = mapActions('auth', ['login','loginGoogle','restaurarContrasena','cambiarContrasena', 'logout', 'registeruser'])

// export const authMethods = mapActions('auth', ['login', 'logout', 'registeruser'])

export const layoutMethods = mapActions('layout', ['changeLayoutType', 'changeLayoutWidth', 'changeLeftSidebarType', 'changeTopbar', 'changeLoaderValue'])

export const authFackMethods = mapActions('authfack', ['login', 'registeruser', 'logout'])

export const notificationMethods = mapActions('notification', ['success', 'error', 'clear'])

export const todoComputed = {
  ...mapState('todo', {
    todos: (state) => state.todos
  })
}
export const todoMethods = mapActions('todo', ['fetchTodos'])

export const proyectoComputed = {
  ...mapState('proyecto', {
    proyectosUsuario: (state) => state.proyectosUsuario,
    proyectoUsuario: (state) => state.proyectoUsuario,
    resultadoCrearProyecto: (state) => state.resultadoCrearProyecto,
    errorProyecto: (state) => state.errorProyecto
  })
}
export const proyectoMethods = mapActions('proyecto', ['buscarFiltroEtapas','obtenerProyectosContacto','obtenerProyectos','obtenerProyecto','obtenerProyectosUsuario','obtenerProyectosEtapa','seleccionarProyecto','crearProyecto','actualizarProyecto','eliminarProyecto'])


export const comentarioComputed = {
  ...mapState('comentario', {
    comentariosUsuario: (state) => state.comentariosUsuario,
    resultadoCrearComentario: (state) => state.resultadoCrearComentario,
    errorComentario: (state) => state.errorComentario
    
  })
}
export const comentarioMethods = mapActions('comentario', ['obtenerComentarios','crearComentario'])

export const informacionTecnicaComputed = {
  ...mapState('informacionTecnica', {
    informacionTecnica: (state) => state.informacionTecnica,
    informacionTecnicas: (state) => state.informacionTecnicas,
    resultadoCrearInformacionTecnica: (state) => state.resultadoCrearInformacionTecnica,
    errorInformacionTecnica: (state) => state.errorInformacionTecnica
  })
}
export const informacionTecnicaMethods = mapActions('informacionTecnica', ['obtenerInformacionTecnicaId','obtenerInformacionTecnica','crearInformacionTecnica','actualizarInformacionTecnica','eliminarInformacionTecnica'])


export const lugarInstalacionComputed = {
  ...mapState('lugarInstalacion', {
    lugarInstalacion: (state) => state.lugarInstalacion,
    lugaresInstalacion: (state) => state.lugaresInstalacion,
    resultadoCrearLugarInstalacion: (state) => state.resultadoCrearLugarInstalacion,
    errorLugarInstalacion: (state) => state.errorLugarInstalacion
  })
}
export const lugarInstalacionMethods = mapActions('lugarInstalacion', ['actualizarLugarInstalacionActivo','obtenerLugaresInstalacionExcelContacto','obtenerLugaresInstalacionContacto','obtenerLugaresInstalacion','obtenerLugaresInstalacionExcel','obtenerLugarInstalacion','obtenerLugarInstalacionId','crearLugarInstalacion','replicarProyecto','crearLugarInstalacionForm','actualizarLugarInstalacion','actualizarLugarInstalacionImagen','eliminarLugarInstalacion'])

export const contactoComputed = {
  ...mapState('contacto', {
    contacto: (state) => state.contacto,
    contactos: (state) => state.contactos,
    resultadoCrearContacto: (state) => state.resultadoCrearContacto,
    errorContacto: (state) => state.errorContacto
  })
}
export const contactoMethods = mapActions('contacto', ['obtenerContactos','obtenerContacto','crearContacto','crearContactosMasivo','eliminarContacto'])

export const tipoProyectoComputed = {
  ...mapState('tipoProyecto', {
    tipoProyecto: (state) => state.tipoProyecto,
    tipoProyectos: (state) => state.tipoProyectos,
    resultadoCrearTipoProyecto: (state) => state.resultadoCrearTipoProyecto,
    errorTipoProyecto: (state) => state.errorTipoProyecto
  })
}
export const tipoProyectoMethods = mapActions('tipoProyecto', ['obtenerTipoProyectos','obtenerTipoProyecto','crearTipoProyecto','actualizarTipoProyecto','eliminarTipoProyecto'])

export const tipoVentaComputed = {
  ...mapState('tipoVenta', {
    tipoVenta: (state) => state.tipoVenta,
    tipoVentas: (state) => state.tipoVentas,
    resultadoCrearTipoVenta: (state) => state.resultadoCrearTipoVenta,
    errorTipoVenta: (state) => state.errorTipoVenta
  })
}
export const tipoVentaMethods = mapActions('tipoVenta', ['obtenerTipoVentas','obtenerTipoVenta','crearTipoVenta','actualizarTipoVenta','eliminarTipoVenta'])

export const etapaProyectoComputed = {
  ...mapState('etapaProyecto', {
    etapaProyecto: (state) => state.etapaProyecto,
    etapasProyecto: (state) => state.etapasProyecto,
    resultadoCrearEtapaProyecto: (state) => state.resultadoCrearEtapaProyecto,
    errorEtapaProyecto: (state) => state.errorEtapaProyecto
  })
}
export const etapaProyectoMethods = mapActions('etapaProyecto', ['obtenerEtapasProyecto','obtenerEtapaProyecto','crearEtapaProyecto','actualizarEtapaProyecto','eliminarEtapaProyecto'])

export const subEtapaProyectoComputed = {
  ...mapState('subEtapaProyecto', {
    subEtapaProyecto: (state) => state.subEtapaProyecto,
    subEtapasProyecto: (state) => state.subEtapasProyecto,
    resultadoCrearSubEtapaProyecto: (state) => state.resultadoCrearSubEtapaProyecto,
    errorSubEtapaProyecto: (state) => state.errorSubEtapaProyecto
    
  })
}
export const subEtapaProyectoMethods = mapActions('subEtapaProyecto', ['obtenerSubEtapasProyecto','obtenerSubEtapaProyecto','crearSubEtapaProyecto','actualizarSubEtapaProyecto','eliminarSubEtapaProyecto'])

export const localizacionComputed = {
  ...mapState('localizacion', {
    regiones: (state) => state.regiones,
    comunasRegion: (state) => state.comunasRegion,
    errorLocalizacion: (state) => state.errorLocalizacion,
  })
}
export const localizacionMethods = mapActions('localizacion', ['obtenerRegiones','obtenerComunasRegion'])


export const tipoInstalacionComputed = {
  ...mapState('tipoInstalacion', {
    tipoInstalacion: (state) => state.tipoInstalacion,
    tipoInstalaciones: (state) => state.tipoInstalaciones,
    resultadoCrearTipoInstalacion: (state) => state.resultadoCrearTipoInstalacion,
    errorTipoInstalacion: (state) => state.errorTipoInstalacion
  })
}
export const tipoInstalacionMethods = mapActions('tipoInstalacion', ['obtenerTipoInstalaciones','obtenerTipoInstalacion','crearTipoInstalacion','actualizarTipoInstalacion','eliminarTipoInstalacion'])

export const tipoClienteComputed = {
  ...mapState('tipoCliente', {
    tipoCliente: (state) => state.tipoCliente,
    tipoClientes: (state) => state.tipoClientes,
    resultadoCrearTipoCliente: (state) => state.resultadoCrearTipoCliente,
    errorTipoCliente: (state) => state.errorTipoCliente
  })
}
export const tipoClienteMethods = mapActions('tipoCliente', ['obtenerTipoClientes','obtenerTipoCliente','crearTipoCliente','actualizarTipoCliente','eliminarTipoCliente'])


export const normativaComputed = {
  ...mapState('normativa', {
    normativa: (state) => state.normativa,
    normativas: (state) => state.normativas,
    resultadoCrearNormativa: (state) => state.resultadoCrearNormativa,
    errorNormativa: (state) => state.errorNormativa
  })
}
export const normativaMethods = mapActions('normativa', ['obtenerNormativas','obtenerNormativa','crearNormativa','actualizarNormativa','eliminarNormativa'])

export const appFlexEnergeticaComputed = {
  ...mapState('appFlexEnergetica', {
    appFlexEnergetica: (state) => state.appFlexEnergetica,
    appFlexEnergeticas: (state) => state.appFlexEnergeticas,
    resultadoCrearAppFlexEnergetica: (state) => state.resultadoCrearAppFlexEnergetica,
    errorAppFlexEnergetica: (state) => state.errorAppFlexEnergetica
  })
}
export const appFlexEnergeticaMethods = mapActions('appFlexEnergetica', ['obtenerAppFlexEnergeticas','obtenerAppFlexEnergetica','crearAppFlexEnergetica','actualizarAppFlexEnergetica','eliminarAppFlexEnergetica'])

export const actividadComputed = {
  ...mapState('actividad', {
    actividad: (state) => state.actividad,
    actividades: (state) => state.actividades,
    resultadoCrearActividad: (state) => state.resultadoCrearActividad,
    errorActividad: (state) => state.errorActividad
  })
}
export const actividadMethods = mapActions('actividad', ['obtenerActividades','obtenerActividad','crearActividad','actualizarActividad','eliminarActividad'])


export const actividadTipoProyectoConfiguracionComputed = {
  ...mapState('actividadTipoProyectoConfiguracion', {
    actividadTipoProyectoConfiguracion: (state) => state.actividadTipoProyectoConfiguracion,
    actividadTipoProyectoConfiguraciones: (state) => state.actividadTipoProyectoConfiguraciones,
    resultadoCrearActividadTipoProyectoConfiguracion: (state) => state.resultadoCrearActividadTipoProyectoConfiguracion,
    resultadoActualizarActividadTipoProyectoConfiguracion: (state) => state.resultadoActualizarActividadTipoProyectoConfiguracion,
    errorActividadTipoProyectoConfiguracion: (state) => state.errorActividadTipoProyectoConfiguracion
  })
}
export const actividadTipoProyectoConfiguracionMethods = mapActions('actividadTipoProyectoConfiguracion', ['obtenerActividadTipoProyectoConfiguraciones','obtenerActividadTipoProyectoConfiguracion','crearActividadTipoProyectoConfiguracion','actualizarActividadTipoProyectoConfiguracion','eliminarActividadTipoProyectoConfiguracion'])


export const actividadProyectoComputed = {
  ...mapState('actividadProyecto', {
    actividadProyecto: (state) => state.actividadProyecto,
    actividadesProyecto: (state) => state.actividadesProyecto,
    resultadoCrearActividadProyecto: (state) => state.resultadoCrearActividadProyecto,
    resultadoActualizarActividadProyecto: (state) => state.resultadoActualizarActividadProyecto,
    errorActividadProyecto: (state) => state.errorActividadProyecto
  })
}
export const actividadProyectoMethods = mapActions('actividadProyecto', ['actualizarActividadGanttProyectoTemplate','eliminarGantt','crearActividadProyectoDependenciaTemplate','crearActividadProyectoTemplate','obtenerActividadesProyectoTemplate','eliminarActividadProyectoDependencia','crearActividadProyectoDependencia','obtenerActividadesProyectoFecha','actualizarActividadProyectoOrden','obtenerActividadesProyecto','obtenerActividadProyectoId','crearActividadProyecto','crearActividadProyectoGantt','crearActividadesProyectoBase','actualizarActividadProyecto','actualizarActividadGanttProyecto','eliminarActividadProyecto'])


export const galeriaImagenesComputed = {
  ...mapState('galeriaImagenes', {
    imagenesProyecto: (state) => state.imagenesProyecto,
    errorGaleriaImagenes: (state) => state.errorGaleriaImagenes
  })
}
export const galeriaImagenesMethods = mapActions('galeriaImagenes', ['obtenerImagenes'])


export const usuarioComputed = {
  ...mapState('usuario', {
    usuario: (state) => state.usuario,
    usuarios: (state) => state.usuarios,
    resultadoCrearUsuario: (state) => state.resultadoCrearUsuario,
    errorUsuario: (state) => state.errorUsuario
  })
}
export const usuarioMethods = mapActions('usuario', ['obtenerUsuarios','obtenerUsuario','obtenerUsuarioTokenUsuario','crearUsuario','crearUsuarioForm','actualizarUsuario','actualizarUsuarioForm','actulizarUsuarioPerfil','actualizarUsuarioContacto','activarDesactivarUsuario','debeCambiarClave','eliminarUsuario'])

export const cargoComputed = {
  ...mapState('cargo', {
    cargo: (state) => state.cargo,
    cargos: (state) => state.cargos,
    resultadoCrearCargo: (state) => state.resultadoCrearCargo,
    errorCargo: (state) => state.errorCargo
  })
}
export const cargoMethods = mapActions('cargo', ['obtenerCargos','obtenerCargo','crearCargo','actualizarCargo','eliminarCargo'])

export const documentoComputed = {
  ...mapState('documento', {
    documento: (state) => state.documento,
    documentos: (state) => state.documentos,
    resultadoCrearDocumento: (state) => state.resultadoCrearDocumento,
    errorDocumento: (state) => state.errorDocumento
  })
}
export const documentoMethods = mapActions('documento', ['obtenerDocumentosFechaSubida','obtenerDocumentos','obtenerDocumentosTipoDocumento','obtenerDocumentosNombreDocumento','obtenerDocumento','crearDocumento','actualizarDocumento','actualizarSinDocumento','eliminarDocumento','actualizarPrivadoDocumento'])

export const tipoDocumentoComputed = {
  ...mapState('tipoDocumento', {
    tipoDocumento: (state) => state.tipoDocumento,
    tipoDocumentos: (state) => state.tipoDocumentos,
    resultadoCrearTipoDocumento: (state) => state.resultadoCrearTipoDocumento,
    errorTipoDocumento: (state) => state.errorTipoDocumento
  })
}
export const tipoDocumentoMethods = mapActions('tipoDocumento', ['obtenerTipoDocumentos','obtenerTipoDocumento','crearTipoDocumento','actualizarTipoDocumento','eliminarTipoDocumento'])

export const nombreDocumentoComputed = {
  ...mapState('nombreDocumento', {
    nombreDocumento: (state) => state.nombreDocumento,
    nombreDocumentos: (state) => state.nombreDocumentos,
    resultadoCrearNombreDocumento: (state) => state.resultadoCrearNombreDocumento,
    errorNombreDocumento: (state) => state.errorNombreDocumento
  })
}
export const nombreDocumentoMethods = mapActions('nombreDocumento', ['obtenerNombreDocumentos','obtenerNombreDocumento','obtenerNombreDocumentosPorTipoDocumento','crearNombreDocumento','actualizarNombreDocumento','eliminarNombreDocumento'])

export const productoComputed = {
  ...mapState('producto', {
    productos: (state) => state.productos,
    stockProducto: (state) => state.stockProducto,
    errorProducto: (state) => state.errorProducto

  })
}
export const productoMethods = mapActions('producto', ['obtenerProductos','obtenerStockProductos'])

export const solicitudMaterialComputed = {
  ...mapState('solicitudMaterial', {
    solicitudMaterial: (state) => state.solicitudMaterial,
    solicitudMateriales: (state) => state.solicitudMateriales,
    resultadoCrearSolicitudMaterial: (state) => state.resultadoCrearSolicitudMaterial,
    errorSolicitudMaterial: (state) => state.errorSolicitudMaterial
  })
}
export const solicitudMaterialMethods = mapActions('solicitudMaterial', ['obtenerSolicitudMaterialesBodegaUsuarioBodega','obtenerSolicitudMaterialesBodegaUsuarioMovil','productosSolicitadosNuevamente','obtenerSolicitudMateriales','filtro','excelGeneral','excel','obtenerSolicitudMaterialesBodega','obtenerSolicitudMaterialesBodegaMovil','obtenerSolicitudMaterial','obtenerSolicitudMaterialesProyectoLugarInstalacion','crearSolicitudMaterial','actualizarSolicitudMaterial','actualizarSolicitudMaterialProductoRecepcionado','actualizarSolicitudMaterialRechazada','actualizarSolicitudMaterialRecepcionada','actualizarSolicitudMaterialValeConsumo','actualizarSolicitudMaterialProductoCantidadRecepcionada','actualizarSolicitudMaterialDevolucion','actualizarSolicitudMaterialProductoCantidadConsumida','actualizarSolicitudMaterialProductoCantidadDevuelta','eliminarSolicitudMaterial','obtenerSolicitudMaterialDetalles'])

export const rolComputed = {
  ...mapState('rol', {
    rol: (state) => state.rol,
    roles: (state) => state.roles,
    resultadoCrearRol: (state) => state.resultadoCrearRol,
    errorRol: (state) => state.errorRol
  })
}
export const rolMethods = mapActions('rol', ['obtenerRoles','obtenerRolesActivos','obtenerRol','crearRol','actualizarRol','eliminarRol'])

export const moduloPermisoComputed = {
  ...mapState('moduloPermiso', {
    moduloPermiso: (state) => state.moduloPermiso,
    moduloPermisos: (state) => state.moduloPermisos,
    resultadoCrearModuloPermiso: (state) => state.resultadoCrearModuloPermiso,
    errorModuloPermiso: (state) => state.errorModuloPermiso
  })
}
export const moduloPermisoMethods = mapActions('moduloPermiso', ['obtenerModuloPermisos','obtenerModuloPermisosActivos','obtenerModuloPermiso','crearModuloPermiso','actualizarModuloPermiso','eliminarModuloPermiso'])

export const rolModuloPermisoComputed = {
  ...mapState('rolModuloPermiso', {
    rolModuloPermiso: (state) => state.rolModuloPermiso,
    rolModuloPermisos: (state) => state.rolModuloPermisos,
    resultadoCrearRolModuloPermiso: (state) => state.resultadoCrearRolModuloPermiso,
    errorRolModuloPermiso: (state) => state.errorRolModuloPermiso
  })
}
export const rolModuloPermisoMethods = mapActions('rolModuloPermiso', ['obtenerRolModuloPermisos','obtenerRolModuloPermisosActivos','obtenerRolModuloPermiso','crearRolModuloPermiso','actualizarRolModuloPermiso','eliminarRolModuloPermiso'])

export const usuarioBodegaComputed = {
  ...mapState('usuarioBodega', {
    usuarioBodega: (state) => state.usuarioBodega,
    usuarioBodegas: (state) => state.usuarioBodegas,
    resultadoCrearUsuarioBodega: (state) => state.resultadoCrearUsuarioBodega,
    errorUsuarioBodega: (state) => state.errorUsuarioBodega
  })
}
export const usuarioBodegaMethods = mapActions('usuarioBodega', ['obtenerUsuarioBodegasTokenUsuario','obtenerUsuarioBodegas','obtenerUsuarioBodega','crearUsuarioBodega','actualizarUsuarioBodega','eliminarUsuarioBodega'])

export const bodegaComputed = {
  ...mapState('bodega', {
    bodega: (state) => state.bodega,
    bodegas: (state) => state.bodegas,
    resultadoCrearBodega: (state) => state.resultadoCrearBodega,
    errorBodega: (state) => state.errorBodega
  })
}
export const bodegaMethods = mapActions('bodega', ['obtenerBodegas','obtenerBodega'])


export const actividadProyectoAvanceDiarioComputed = {
  ...mapState('actividadProyectoAvanceDiario', {
    actividadProyectoAvanceDiario: (state) => state.actividadProyectoAvanceDiario,
    actividadProyectoAvanceDiarios: (state) => state.actividadProyectoAvanceDiarios,
    resultadoCrearActividadProyectoAvanceDiario: (state) => state.resultadoCrearActividadProyectoAvanceDiario,
    resultadoActualizarActividadProyectoAvanceDiario: (state) => state.resultadoActualizarActividadProyectoAvanceDiario,
    errorActividadProyectoAvanceDiario: (state) => state.errorActividadProyectoAvanceDiario
  })
}
export const actividadProyectoAvanceDiarioMethods = mapActions('actividadProyectoAvanceDiario', ['obtenerAcumuladoActividad','obtenerActividadProyectoAvanceDiarioDetalles','obtenerActividadProyectoAvanceDiarioDetalleActividad','obtenerActividadProyectoAvanceDiarioDetalleId','crearActividadProyectoAvanceDiarioDetalle','actualizarActividadProyectoAvanceDiarioDetalle','eliminarActividadProyectoAvanceDiarioDetalle','obtenerActividadProyectoAvanceDiarios','obtenerActividadProyectoAvanceDiariosFecha','obtenerActividadProyectoAvanceDiarioId','crearActividadProyectoAvanceDiario','actualizarActividadProyectoAvanceDiario','eliminarActividadProyectoAvanceDiario'])

export const actividadProyectoPersonalObraHorasTrabajadasComputed = {
  ...mapState('actividadProyectoPersonalObraHorasTrabajadas', {
    actividadProyectoPersonalObraHorasTrabajadas: (state) => state.actividadProyectoPersonalObraHorasTrabajadas,
    actividadesProyectoPersonalObraHorasTrabajadas: (state) => state.actividadesProyectoPersonalObraHorasTrabajadas,
    resultadoCrearActividadProyectoPersonalObraHorasTrabajadas: (state) => state.resultadoCrearActividadProyectoPersonalObraHorasTrabajadas,
    resultadoActualizarActividadProyectoPersonalObraHorasTrabajadas: (state) => state.resultadoActualizarActividadProyectoPersonalObraHorasTrabajadas,
    errorActividadProyectoPersonalObraHorasTrabajadas: (state) => state.errorActividadProyectoPersonalObraHorasTrabajadas
  })
}
export const actividadProyectoPersonalObraHorasTrabajadasMethods = mapActions('actividadProyectoPersonalObraHorasTrabajadas', ['obtenerActividadProyectoPersonalObraHorasTrabajadasPersonalTodo','obtenerActividadProyectoPersonalObraHorasTrabajadasPersonal','obtenerPersonal','obtenerActividadProyectoPersonalObraHorasTrabajadas','obtenerActividadProyectoPersonalObraHorasTrabajadasFecha','obtenerActividadProyectoPersonalObraHorasTrabajadasId','crearActividadProyectoPersonalObraHorasTrabajadas','actualizarActividadProyectoPersonalObraHorasTrabajadas','eliminarActividadProyectoPersonalObraHorasTrabajadas'])

export const actividadProyectoPersonalObraComputed = {
  ...mapState('actividadProyectoPersonalObra', {
    actividadProyectoPersonalObra: (state) => state.actividadProyectoPersonalObra,
    actividadesProyectoPersonalObra: (state) => state.actividadesProyectoPersonalObra,
    resultadoCrearActividadProyectoPersonalObra: (state) => state.resultadoCrearActividadProyectoPersonalObra,
    resultadoActualizarActividadProyectoPersonalObra: (state) => state.resultadoActualizarActividadProyectoPersonalObra,
    errorActividadProyectoPersonalObra: (state) => state.errorActividadProyectoPersonalObra
  })
}
export const actividadProyectoPersonalObraMethods = mapActions('actividadProyectoPersonalObra', ['obtenerActividadProyectoPersonalObraActividad','obtenerPersonal','obtenerActividadProyectoPersonalObra','obtenerActividadProyectoPersonalObraFecha','obtenerActividadProyectoPersonalObraId','crearActividadProyectoPersonalObra','actualizarActividadProyectoPersonalObra','eliminarActividadProyectoPersonalObra'])

export const personalComputed = {
  ...mapState('personal', {
    personales: (state) => state.personales,
    personal: (state) => state.personal,
    resultadoCrearPersonal: (state) => state.resultadoCrearPersonal,
    errorPersonal: (state) => state.errorPersonal
  })
}
export const personalMethods = mapActions('personal', ['obtenerPersonal','obtenerPersonalId','obtenerPersonalActivo'])

export const unidadComputed = {
  ...mapState('unidad', {
    unidad: (state) => state.unidad,
    unidades: (state) => state.unidades,
    resultadoCrearUnidad: (state) => state.resultadoCrearUnidad,
    errorUnidad: (state) => state.errorUnidad
  })
}
export const unidadMethods = mapActions('unidad', ['obtenerUnidades','obtenerUnidad','crearUnidad','actualizarUnidad','eliminarUnidad'])

export const solicitudMaterialDespachoComputed = {
  ...mapState('solicitudMaterialDespacho', {
    solicitudMaterialDespacho: (state) => state.solicitudMaterialDespacho,
    solicitudMaterialDespachos: (state) => state.solicitudMaterialDespachos,
    resultadoCrearSolicitudMaterialDespacho: (state) => state.resultadoCrearSolicitudMaterialDespacho,
    errorSolicitudMaterialDespacho: (state) => state.errorSolicitudMaterialDespacho
  })
}
export const solicitudMaterialDespachoMethods = mapActions('solicitudMaterialDespacho', ['obtenerSolicitudMaterialDespachosSolicitudNumero','obtenerSolicitudMaterialDespachos','obtenerSolicitudMaterialDespacho','crearSolicitudMaterialDespacho','actualizarSolicitudMaterialDespacho','eliminarSolicitudMaterialDespacho'])


export const templateComputed = {
  ...mapState('templateGantt', {
    template: (state) => state.template,
    templates: (state) => state.templates,
    resultadoCrearTemplate: (state) => state.resultadoCrearTemplate,
    errorTemplate: (state) => state.errorTemplate
  })
}
export const templateMethods = mapActions('templateGantt', ['obtenerTemplates','obtenerTemplateTipoProyecto','obtenerTemplate','crearTemplate','actualizarTemplate','eliminarTemplate'])